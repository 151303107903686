import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import 'normalize.css';
import '../styles/typography.css';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet-async';
import GlobalStyles from '../styles/GlobalStyles';

const SiteBackgroundStyles = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    var(--backgroundbar),
    var(--backgroundbar) 100px,
    var(--background) 100px
  );
  z-index: -99;
  min-height: 100%;
`;

const PageWrapperStyles = styled.div`
  margin-top: 3.3rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    margin-top: 2rem;
  }
  @media (max-width: 768px) {
    margin-top: 1.1rem;
  }
`;

const ContentAreaStyles = styled.div`
  width: 100%;
  max-width: 1000px;
  min-height: 100px;
  background-color: var(--white);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.15);
`;

const FooterStyles = styled.footer`
  width: 100%;
  height: 5rem;
  background-color: var(--footer);
  color: #8c8c8c;
  a {
    text-decoration: none;
    transition: 0.3s;
    color: #8c8c8c;
    margin-right: 20px;
    &:hover {
      color: var(--white);
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1000px;
    margin-left: 4rem;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="/fonts/baloo-2-v1-latin-regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
          as="font"
        />
        <link
          rel="preload"
          href="/fonts/baloo-2-v1-latin-600.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
          as="font"
        />
        <link
          rel="preload"
          href="/fonts/baloo-2-v1-latin-700.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
          as="font"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#982512" />
        <meta name="msapplication-TileColor" content="#982512" />
        <meta name="theme-color" content="#982512" />
      </Helmet>
      <GatsbySeo />
      <GlobalStyles />
      <SiteBackgroundStyles />
      <PageWrapperStyles>
        <ContentAreaStyles>{children}</ContentAreaStyles>
      </PageWrapperStyles>
      <FooterStyles>
        <div>
          <Link to="/paartherapie/">Paartherapie</Link>
          <Link to="/datenschutz/">Datenschutz</Link>
          <Link to="/impressum/">Impressum</Link>
        </div>
      </FooterStyles>
    </>
  );
}
