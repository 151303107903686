module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"de","openGraph":{"type":"website","locale":"de_DE","url":"https://psychotherapieravensburg.de","site_name":"Psychotherapie Ravensburg - Sarah und Florian Such"},"titleTemplate":"%s | Psychotherapie Ravensburg - Sarah und Florian Such"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"psychotherapieravensburg.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
