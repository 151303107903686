import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --highlight: #982512;
    --headings: #333;
    --text: #666;
    --backgroundbar: #b1b1b1;
    --background: #f9f9f9;
    --white: #fff;
    --shadow: #efefef;
    --footer: #3d3d3d;
  }

  html {
    font-size: 10px;
  }

  body {
    font-size: 1.6rem;
    line-height: 1.5em;
  }

  button, .button {
    background: var(--highlight);
    display: inline-block;
    color: var(--white);
    border: 0;
    padding: 0.8rem 2.5rem 0.6rem 2.5rem;
    margin: 2.5rem 0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
    outline: none;
    }
    &:focus-visible {
    outline: 1px dotted white;
    }
    font-size: 2.6rem;
  }

  ul {
    ${'' /* list-style-type: '• '; */}
    list-style-type: none;
    list-style-position: inside;
    padding-left: 0.5em;
    li:before {
      content: '• ';
      margin-left: -0.5em;
    }
  }
`;

export default GlobalStyles;
